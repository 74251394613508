<template>
  <div class="main-container">

    <Header />

    <Breadcrumb :key="id" :items="items" :title="$d.serviceList[id].title" />

    <ServiceDetailsWrapper :id="id" :key="id + 1" />

    <!-- <BrandCarousel add-class="grey-bg" /> -->

    <Footer />

    <OffCanvasMobileMenu />

    <!-- back to top start -->
    <back-to-top class="scroll-top" bottom="60px">
      <i class="ion-android-arrow-up" />
    </back-to-top>
    <!-- back to top end -->
  </div>
</template>

<script>
import Header from '@/components/Header'
import Breadcrumb from '../components/Breadcrumb'
import ServiceDetailsWrapper from '../components/sections/ServiceDetailsWrapper'
import Footer from '../components/Footer'
import OffCanvasMobileMenu from '@/components/OffCanvasMobileMenu'
export default {
  components: {
    Header,
    Breadcrumb,
    ServiceDetailsWrapper,
    Footer,
    OffCanvasMobileMenu
  },
  data() {
    return {
    }
  },
  computed: {
    id() {
      return parseInt(this.$route.query.id)
    },
    items() {
      return [
        {
          text: '业务范围',
          to: '/service'
        }, {}
      ]
    }
  },
  metaInfo: {
    title: '乌节国际 - 业务详情',
    htmlAttrs: {
      lang: 'zh-cn',
      amp: true
    }
  }
}
</script>

