<template>
  <aside>
    <div class="sidebar-widget">
      <h3 class="sidebar-title">业务范围</h3>
      <ul class="sidebar-list">
        <li v-for="(list,index) in serviceSidebar" :key="index">
          <router-link :to="'/service-details?id='+index">
            <i :class="list.icon" />
            {{ list.title }}
          </router-link>
        </li>
      </ul>
    </div>
  </aside>
</template>

<script>
export default {
  props: ['serviceSidebar']
}
</script>
